import React, { useEffect } from 'react'
import _ from 'lodash'
import {
  Grid,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core'
import { defaultDateFormat, moneyFormat } from '../../formatters'
import { useStateValue } from '../../redux'
import { invoiceActions } from '../../redux/invoices/invoiceActions'
import { Label, FieldGridColumn } from './styledComponents'
import { InvoiceFileBtn } from './InvoiceFileBtn'

interface Props {
  onClose: () => void
  open: boolean
  invoiceID: number
}

const INVOICE_DETAIL = 'invoice_detail'

export const InvoiceDialog = ({ invoiceID, ...props }: Props) => {
  const [{ invoices }, dispatch]: any = useStateValue()
  const { invoice } = invoices
  const { getInvoice } = invoiceActions
  const handleClose = () => {
    props.onClose()
  }

  useEffect(() => {
    getInvoice(invoiceID, dispatch)
  }, [invoiceID, dispatch, getInvoice])

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      scroll="paper"
      data-testid="invoice-dialog"
      PaperProps={{
        style: {
          minWidth: 800,
        },
      }}>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <h3>Invoice Details</h3>
          </Grid>
          <FieldGridColumn item xs={3}>
            <Label>Invoice No.</Label>
            <Typography>{_.get(invoice, 'Number', null)}</Typography>
          </FieldGridColumn>
          <FieldGridColumn item xs={3}>
            <Label>Status</Label>
            <Typography>{_.get(invoice, 'Status', null)}</Typography>
          </FieldGridColumn>
          <FieldGridColumn item xs={3}>
            <Label>Due Date</Label>
            <Typography>
              {defaultDateFormat(_.get(invoice, 'DueDate', 'Not yet approved'))}
            </Typography>
          </FieldGridColumn>
          <FieldGridColumn item xs={3}>
            <Label>Amount</Label>
            <Typography>
              {moneyFormat(_.get(invoice, 'Amount', 'Not yet approved'))}
            </Typography>
          </FieldGridColumn>

          <Grid item xs={12}>
            <h3>Transitions</h3>
          </Grid>

          <FieldGridColumn item xs={3}>
            <Label>Issued On</Label>
            <Typography>
              {defaultDateFormat(_.get(invoice, 'DateOfIssue', null))}
            </Typography>
          </FieldGridColumn>

          <FieldGridColumn item xs={3}>
            <Label>Approved On</Label>
            <Typography>
              {defaultDateFormat(
                _.get(invoice, 'ApprovedDate', 'Not yet approved')
              )}
            </Typography>
          </FieldGridColumn>
          <FieldGridColumn item xs={3}>
            <Label>Funded On</Label>
            <Typography>
              {defaultDateFormat(
                _.get(invoice, 'FundedDate', 'Not yet funded')
              )}
            </Typography>
          </FieldGridColumn>
          <Grid item xs={12}>
            <h3>Files</h3>
          </Grid>
          <FieldGridColumn item xs={6}>
            {invoice.Files?.map((file: any) => {
              const extension =
                file.FileType?.Hash === INVOICE_DETAIL ? 'csv' : 'pdf'
              return (
                <InvoiceFileBtn
                  key={file.ID}
                  fileID={file.ID}
                  filename={`View ${file.FileType?.Description} (${extension})`}
                  invoiceID={invoice.ID}
                  fileType={file.FileType?.Hash}
                />
              )
            })}
          </FieldGridColumn>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
